import { type ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  // if (process.env.REACT_APP_ENV !== 'production') {
  if (onPerfEntry) {
    // eslint-disable-next-line global-require
    const { getCLS, getFID, getFCP, getLCP, getTTFB } = require('web-vitals');
    // import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getFCP(onPerfEntry);
    // @ts-ignore
    getLCP(perfEntry => {
      perfEntry.entries.forEach((entry: any) => {
        console.log(entry.element?.outerHTML);
      });
      onPerfEntry?.(perfEntry);
    });
    getTTFB(onPerfEntry);
    // });
  }
  // }
};

export default reportWebVitals;

import {
  SkeletonDisplayText,
  Text,
  Layout,
  LegacyCard,
  Page,
  Banner,
} from '@shopify/polaris';
import { useNavigate } from '@shopify/app-bridge-react/hooks/useNavigate';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAnalyticsQuery } from '../../services/queries.service';

export default function AnalyticsPage() {
  const { data, isLoading } = useAnalyticsQuery();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.refetchQueries(['analytics']);
  }, [queryClient]);

  return (
    <Page
      title='Analytics'
      primaryAction={{
        content: 'View report',
        // url: '/invoices',
        onAction: () => {
          navigate('/invoices');
        },
      }}
    >
      <Layout>
        <Layout.Section oneThird>
          <LegacyCard sectioned title='Total Orders Received Last Month'>
            {isLoading ? (
              <SkeletonDisplayText size='medium' />
            ) : (
              <Text variant='heading2xl' as='p'>
                {data!.totalOrdersLastMonth}
              </Text>
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section oneThird>
          <LegacyCard
            sectioned
            title='Total Invoice Emails Sent For Orders In Last Month'
          >
            {isLoading ? (
              <SkeletonDisplayText size='medium' />
            ) : (
              <Text variant='heading2xl' as='p'>
                {data!.totalInvoicesSentLastMonth}
              </Text>
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section oneThird>
          <LegacyCard
            sectioned
            title='Total Invoice Emails Sent For Orders In This Month'
          >
            {isLoading ? (
              <SkeletonDisplayText size='medium' />
            ) : (
              <Text variant='heading2xl' as='p'>
                {data!.totalInvoicesSentThisMonth}
              </Text>
            )}
          </LegacyCard>
        </Layout.Section>
        <Layout.Section fullWidth>
          <Banner status='info'>
            Now you can view/download your invoices from your orders page
            itself. Select particular orders (50 max) or open a particular order
            and choose Invoice Hero features from More actions drop down.
          </Banner>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

import { lazy, Suspense } from 'react';
// import LoadingCom from '../../components/LoadingCom';
import LoadingCom from '../../components/DashboardLoadingCom';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const DashBoardPage = lazy(() => import('./Index'));

export default function SuspendedDashboardPage() {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <DashBoardPage />
      </Suspense>
    </PageErrorBoundary>
  );
}

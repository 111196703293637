import { useEffect, useState } from 'react';
import { Redirect, Toast as ToastAction } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import getApp from '../../services/app-bridge.service';
import useShowContinueToAppBtn from './utils/useShowContinueToAppBtn';
import { httpInterceptor } from '../../services/api.service';

function SendInvoiceToCustomerForDraftOrder() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [, setRes] = useState(null);
  const [err, setErr] = useState<null | AxiosError<{ message: string }>>(null);
  const showContinueToAppBtn = useShowContinueToAppBtn();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    const shop = searchParams.get('shop');

    httpInterceptor
      .get('/api/order/draft/send', {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        params: {
          id,
          shop,
        },
      })
      .then(res => {
        setRes(res.data);
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <h1>Loading...</h1>;

  const app = getApp()!;

  if (err && (!err.response || err.response.status !== 400)) {
    const toastAction = ToastAction.create(app, {
      message: 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    showContinueToAppBtn();
    return null;
  }

  if (err) {
    const toastAction = ToastAction.create(app, {
      message: err!.response!.data.message,
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);

    const btn = document.querySelector<HTMLButtonElement>('#root .btn')!;
    if (btn) {
      btn.onclick = () => {
        const redirect = Redirect.create(app);
        redirect.dispatch(Redirect.Action.APP, '/pricing');
      };
      btn.textContent = 'Upgrade to paid plan';
      btn.disabled = false;
      btn.style.display = 'inline-block';
    }
    return null;
  }

  const toastAction = ToastAction.create(app, {
    message: 'Mail sent successfully!',
    duration: 5000,
  });
  toastAction.dispatch(ToastAction.Action.SHOW);
  setTimeout(() => {
    navigate(-1);
    showContinueToAppBtn();
  }, 5000);
  return <h1>Going Back in 5 seconds</h1>;
}

export default SendInvoiceToCustomerForDraftOrder;

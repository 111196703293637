import { lazy, Suspense } from 'react';
import LoadingCom from '../../components/LoadingCom';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const Support = lazy(() => import('./index'));

export default function SuspendedSupportDetail(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <Support {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}

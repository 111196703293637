import { Toast as ToastAction } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import getApp from '../../services/app-bridge.service';
import useShowContinueToAppBtn from './utils/useShowContinueToAppBtn';
import { httpInterceptor } from '../../services/api.service';

function BulkAction({
  url,
  successMessage,
}: {
  successMessage: string;
  url: string;
}) {
  const navigate = useNavigate();
  const showContinueToAppBtn = useShowContinueToAppBtn();
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);
    const ids = pageSearchParams.getAll('ids[]');
    const shop = pageSearchParams.get('shop');

    httpInterceptor
      .get(url, {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        params: {
          ids,
          shop,
        },
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  if (loading) return <h1>Loading...</h1>;

  if (err) {
    const app = getApp();
    const toastAction = ToastAction.create(app!, {
      message: 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    showContinueToAppBtn();
    return null;
  }

  const app = getApp();
  const toastAction = ToastAction.create(app!, {
    message: successMessage,
    duration: 5000,
  });
  toastAction.dispatch(ToastAction.Action.SHOW);
  setTimeout(() => {
    navigate(-1);
    showContinueToAppBtn();
  }, 5000);
  return <h1>Going Back in 5 seconds</h1>;
}

export default BulkAction;

import { useEffect, useState } from 'react';
import { Toast as ToastAction } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import getApp from '../../services/app-bridge.service';
import useShowContinueToAppBtn from './utils/useShowContinueToAppBtn';
import { httpInterceptor } from '../../services/api.service';

function SendInvoiceToCustomerForOrder() {
  const [loading, setLoading] = useState(true);
  const [, setRes] = useState(null);
  const [err, setErr] = useState<null | AxiosError>(null);
  const navigate = useNavigate();
  const showContinueToAppBtn = useShowContinueToAppBtn();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    const shop = searchParams.get('shop');
    const isSendMail = searchParams.get('isSendMail');

    httpInterceptor
      .get('/api/order/invoice', {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        params: {
          id,
          shop,
          isSendMail,
        },
      })
      .then(res => {
        setRes(res.data);
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <h1>Loading...</h1>;

  if (err && (!err.response || err.response.status !== 400)) {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message: 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    showContinueToAppBtn();
    return null;
  }

  if (err) {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message:
        "We are unable to send the invoice for your order. Please make sure the customer's email id is not empty in the order details.",
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    showContinueToAppBtn();
    return null;
  }

  const app = getApp()!;
  const toastAction = ToastAction.create(app, {
    message: 'Successfully sent mail to the customer!',
    duration: 5000,
  });
  toastAction.dispatch(ToastAction.Action.SHOW);
  setTimeout(() => {
    navigate(-1);
    showContinueToAppBtn();
  }, 5000);
  return <h1>Going Back in 5 seconds</h1>;
}

export default SendInvoiceToCustomerForOrder;

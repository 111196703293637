import '@shopify/polaris/build/esm/styles.css';
import { StrictMode, useEffect, useMemo } from 'react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { createRoot } from 'react-dom/client';
import { AppBridgeContext as Context } from '@shopify/app-bridge-react/context';
import { ClientRouter } from '@shopify/app-bridge-react/components/ClientRouter';
import { BrowserRouter as Router, To, useNavigate } from 'react-router-dom';
import { AppProvider } from '@shopify/polaris';
import AppQueryClientProvider from './contexts/react-query.context';
import getAppBridgeInstance from './services/app-bridge.service';
import App from './App';

function Main({ isSetupCompleted }: { isSetupCompleted: boolean }) {
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // const location = useLocation();
  const history = useMemo(
    () => ({ replace: (path: To) => navigate(path, { replace: true }) }),
    [navigate],
  );

  useEffect(() => {
    if (!isSetupCompleted) {
      navigate('/step1');
    }
    // setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const router = useMemo(
  //   () => ({
  //     location,
  //     history,
  //   }),
  //   [location, history],
  // );

  return (
    <AppQueryClientProvider>
      <ClientRouter history={history} />
      <AppProvider i18n={enTranslations}>
        {/* <RedirectButton /> */}
        {/* {!loading && <App />} */}
        <App />
      </AppProvider>
    </AppQueryClientProvider>
  );
}

export default function renderMainApp(isSetupCompleted = true) {
  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <StrictMode>
      <Context.Provider value={getAppBridgeInstance()!}>
        <Router>
          <Main isSetupCompleted={isSetupCompleted} />
        </Router>
      </Context.Provider>
    </StrictMode>,
  );
}

import { PropsWithChildren } from 'react';
import { Banner, LegacyCard, Page } from '@shopify/polaris';

export default function DashboardPageLayout({ children }: PropsWithChildren) {
  return (
    <Page title='Dashboard'>
      <div style={{ maxWidth: '100%', marginBottom: '2rem' }}>
        <LegacyCard>{children}</LegacyCard>
      </div>
      <Banner status='info'>
        Now you can view/download your invoices from your orders page itself.
        Select particular orders (50 max) or open a particular order and choose
        Invoice Hero features from More actions drop down.
      </Banner>
    </Page>
  );
}

import { useNavigate } from '@shopify/app-bridge-react/hooks/useNavigate';

export default function useShowContinueToAppBtn() {
  const navigate = useNavigate();

  return (): void => {
    const btn = document.querySelector<HTMLButtonElement>('#root .btn')!;
    if (btn) {
      btn.onclick = () => {
        btn.style.display = 'none';
        navigate('/');
      };
      btn.textContent = 'Continue to app';
      btn.disabled = false;
      btn.style.display = 'inline-block';
    }
  };
}

import { lazy, Suspense } from 'react';
import { Loading } from '../../components/Loading';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const Settings = lazy(() => import('./index'));

export default function SuspendedSettings(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Settings {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}

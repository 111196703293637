import { lazy, Suspense } from 'react';
import LoadingCom from '../../components/LoadingCom';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const CurrencySettingsPage = lazy(() => import('./index'));

export default function SuspendedCurrencySettingsPage() {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <CurrencySettingsPage />
      </Suspense>
    </PageErrorBoundary>
  );
}

import { createRoot } from 'react-dom/client';
import AdminLinksApp from './App';

export default function renderAdminLinksApp() {
  const container = document.querySelector('#root #firstEle');
  container.innerHTML = `
    <img src="/images/app-icon.png" width="100" height="100" alt="Invoice Hero Icon"/>
    <div id="firstEleTextContainer"><h1>Invoice Hero</h1></div>
    <button class="btn">Continue</button>
  `;
  // { isSetupCompleted },
  const root = createRoot(document.querySelector('#firstEleTextContainer'));
  root.render(<AdminLinksApp />);
}

import {
  IndexTable,
  SkeletonBodyText,
  Button,
  IndexFilters,
  IndexFiltersMode,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import DashboardPageLayout from '../pages/dashboard/DashboardPageLayout';

function DashboardLoadingCom() {
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Default);
  const rowMarkup = [
    <IndexTable.Row position={0} id=''>
      <IndexTable.Cell>
        <SkeletonBodyText lines={1} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <SkeletonBodyText lines={1} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <SkeletonBodyText lines={1} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <SkeletonBodyText lines={1} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <SkeletonBodyText lines={1} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <SkeletonBodyText lines={1} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <SkeletonBodyText lines={1} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <SkeletonBodyText lines={1} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Button disabled>Actions</Button>
      </IndexTable.Cell>
    </IndexTable.Row>,
  ];
  return (
    <DashboardPageLayout>
      <IndexFilters
        filters={[]}
        cancelAction={{
          onAction: () => {},
          disabled: true,
          loading: false,
        }}
        mode={mode}
        setMode={setMode}
        tabs={[]}
        selected={1}
        onQueryChange={() => {}}
        onQueryClear={() => {}}
        onClearAll={() => {}}
      />
      <IndexTable
        headings={[
          { title: 'Invoice' },
          { title: 'Order' },
          { title: 'Date' },
          { title: 'Name' },
          { title: 'Total', alignment: 'end' },
          { title: 'Payment' },
          { title: 'Fulfillment' },
          { title: 'Status' },
          { title: '' },
        ]}
        itemCount={25}
      >
        {rowMarkup}
        {rowMarkup}
        {rowMarkup}
        {rowMarkup}
        {rowMarkup}
        {rowMarkup}
        {rowMarkup}
        {rowMarkup}
        {rowMarkup}
        {rowMarkup}
      </IndexTable>
    </DashboardPageLayout>
  );
}

export default DashboardLoadingCom;

import { useState, useEffect } from 'react';
import { Redirect, Toast as ToastAction } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import getApp from '../../services/app-bridge.service';
import useShowContinueToAppBtn from './utils/useShowContinueToAppBtn';
import { httpInterceptor } from '../../services/api.service';

function ViewInvoiceForDraftOrder() {
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState<null | { link: string }>(null);
  const [err, setErr] = useState<null | AxiosError<{ message: string }>>(null);
  const navigate = useNavigate();
  const showContinueToAppBtn = useShowContinueToAppBtn();

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);

    httpInterceptor
      .get('/api/order/draft/view', {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        params: {
          id: pageSearchParams.get('id'),
          shop: pageSearchParams.get('shop'),
        },
      })
      .then(axiosRes => {
        setRes(axiosRes.data);
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (
    typeof res !== 'object' ||
    (err && (!err.response || err.response.status !== 400))
  ) {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message: 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    showContinueToAppBtn();
    return null;
  }

  if (err) {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message: err.response!.data!.message,
      isError: true,
      duration: 5000,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);

    const btn = document.querySelector<HTMLButtonElement>('#root .btn')!;
    if (btn) {
      btn.onclick = () => {
        const redirect = Redirect.create(app);
        redirect.dispatch(Redirect.Action.APP, '/pricing');
      };
      btn.textContent = 'Upgrade to paid plan';
      btn.disabled = false;
      btn.style.display = 'inline-block';
    }

    return null;
  }

  window.open(res!.link, '_blank');
  navigate(-1);
  showContinueToAppBtn();
  return null;
}

export default ViewInvoiceForDraftOrder;

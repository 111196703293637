import { useState, useEffect } from 'react';
import { Toast as ToastAction } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import getApp from '../../services/app-bridge.service';
import useShowContinueToAppBtn from './utils/useShowContinueToAppBtn';
import { httpInterceptor } from '../../services/api.service';

function ViewInvoiceForOrder() {
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState<null | string | { link: string }>(null);
  const [err, setErr] = useState<null | AxiosError>(null);
  const navigate = useNavigate();
  const showContinueToAppBtn = useShowContinueToAppBtn();

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);

    httpInterceptor
      .get('/shopify/generateInvoice/getLink', {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        params: {
          id: pageSearchParams.get('id'),
          shop: pageSearchParams.get('shop'),
        },
      })
      .then(axiosRes => {
        setRes(axiosRes.data);
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (err) {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message: 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    showContinueToAppBtn();
    return null;
  }

  if (typeof res === 'string') {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message:
        'Your order has not proceed yet. Please try again after some time.',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    showContinueToAppBtn();
    return null;
  }

  window.open(res!.link, '_blank');
  navigate(-1);

  showContinueToAppBtn();
  return null;
}

export default ViewInvoiceForOrder;

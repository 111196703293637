import { useEffect } from 'react';

function Home() {
  useEffect(() => {
    import('../../renderMainApp').then(m => m.default());
  }, []);

  return <h1>Loading...</h1>;
}

export default Home;

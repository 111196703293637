import { lazy, Suspense } from 'react';
import LoadingCom from '../../components/LoadingCom';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const Discount = lazy(() => import('./Index'));

export default function SuspendedDiscountSettingsPage(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <Discount {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}

import { lazy, Suspense } from 'react';
import LoadingCom from '../../components/LoadingCom';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const Design = lazy(() => import('./Index'));

export default function SuspendedDesignSettingsPage(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <Design {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}

import type { AppBridgeState, ClientApplication } from '@shopify/app-bridge';
import { createApp } from '@shopify/app-bridge/client/Client';
import * as AppLink from '@shopify/app-bridge/actions/Link/AppLink';
import * as NavigationMenu from '@shopify/app-bridge/actions/Menu/NavigationMenu';

let appBridgeInstance: ClientApplication<AppBridgeState> | null = null;
let appNavigation: null | NavigationMenu.NavigationMenu = null;

export const initializeAppBridge = () => {
  appBridgeInstance = createApp({
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY!,
    host: new URLSearchParams(window.location.search).get('host')!,
  });
  appBridgeInstance.error(errData => {
    console.log('Found error:', errData);
  });

  // @ts-ignore
  window.appBridgeInstance = appBridgeInstance;
};
export function createAppNavigation(isSetupCompleted = false) {
  if (!isSetupCompleted) return;
  if (appNavigation) {
    appNavigation.unsubscribe(true);
    appNavigation = null;
  }

  appNavigation = NavigationMenu.create(appBridgeInstance!, {
    items: [
      AppLink.create(appBridgeInstance!, {
        label: 'Dashboard',
        destination: '/',
      }),
      AppLink.create(appBridgeInstance!, {
        label: 'Report',
        destination: '/invoices',
      }),
      AppLink.create(appBridgeInstance!, {
        label: 'Settings',
        destination: '/settings',
      }),
      AppLink.create(appBridgeInstance!, {
        label: 'Pricing',
        destination: '/pricing',
      }),
      AppLink.create(appBridgeInstance!, {
        label: 'Support',
        destination: '/support',
      }),
    ],
  });
}

export default function getAppBridgeInstance() {
  return appBridgeInstance;
}
